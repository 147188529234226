'use client';

import { Box, Link } from '@chakra-ui/react';
import type { MouseEventHandler, PropsWithChildren } from 'react';
import { trackCustomEvent } from '@ifixit/analytics';

export interface RibbonBannerData {
   title: string;
   displayText: string;
   displayTextUrl: string;
}

export interface RibbonBannerProps {
   ribbon?: RibbonBannerData;
}

export function RibbonBanner({ ribbon }: PropsWithChildren<RibbonBannerProps>) {
   ribbon = {
      title: 'Ribbon Banner',
      displayText: 'This is a mock ribbon banner link.',
      displayTextUrl: 'https://ifixit.com/Store',
      ...ribbon,
   };

   if (!ribbon) {
      return null;
   }

   const localizedOnClick = trackRibbonBannerLinkClick(ribbon);

   // Mimic Store banner placement within the hero image
   const carpenterBannerStyles = {
      position: 'absolute',
      top: '0',
      zIndex: '1',
   };

   return (
      <Box
         as="section"
         bg="blue.500"
         color="white"
         p={4}
         textAlign="center"
         className="piwikTrackContent"
      >
         <Link
            href={ribbon.displayTextUrl}
            color="white"
            textDecoration="underline"
            fontWeight="bold"
            onClick={localizedOnClick}
            sx={{
               carpenterBannerStyles,
            }}
         >
            {ribbon.displayText}
         </Link>
      </Box>
   );
}

function trackRibbonBannerLinkClick(ribbon: RibbonBannerData): MouseEventHandler {
   return event => {
      const element = event.target;
      if (element instanceof HTMLAnchorElement && element.href) {
         trackCustomEvent({
            eventCategory: 'Ribbon Banner',
            eventAction: `Ribbon Banner - Link ${element.href} - Clicked`,
            eventName: ribbon.title,
         });
      }
   };
}
