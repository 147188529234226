import { color, fontFamily, space } from '@ifixit/primitives';
import styled from 'styled-components';
import { Document } from './featured_documents_types';

interface DocumentLinkProps {
   document: Document;
   active: boolean;
   onClick: () => void;
}

const DocumentLinkButton = styled.button`
   background: none;
   cursor: pointer;
   color: ${color.gray[500]};
   font-size: 1rem;
   font-family: ${fontFamily.sansSystem};
   line-height: 1.5rem;
   padding: ${space[3]} 0 ${space[3]} ${space[6]};
   border-left: 1px solid ${color.gray[200]};
   width: 100%;
   text-align: left;

   &:hover {
      text-decoration: underline;
   }
`;

const DocumentLinkButtonActive = styled(DocumentLinkButton)`
   color: ${color.gray[900]};
   border-color: ${color.blue[500]};
`;

function DocumentLink(props: DocumentLinkProps) {
   const Link = props.active ? DocumentLinkButtonActive : DocumentLinkButton;
   const document: Document = props.document;
   return <Link onClick={props.onClick}>{document.title}</Link>;
}

export default DocumentLink;
