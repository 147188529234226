import { _js } from '@ifixit/localize';
import { useState, useMemo } from 'react';
import { SearchInput } from 'Shared/search_input';
import { color, fontSize, lineHeight, space, breakpoint, borderRadius } from '@ifixit/primitives';
import styled from 'styled-components';
import trackInPiwikAndGA from 'Shared/Analytics/CombinedGAPiwik';
import deviceNavigator from 'Shared/device_navigator';
import { Button, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/pro-solid-svg-icons/faList';

const MOBILE = `@media only screen and (max-width: ${breakpoint.sm})`;

const heroContentWidth = `
   width: ${breakpoint.sm};
   ${MOBILE} {
      padding-inline: ${space[4]};
      text-wrap: pretty;
      width: 100%;
   }
`;

const heroTextFontSize = `
   font-size: ${fontSize['5xl']};
   line-height: ${lineHeight.tight};

   ${MOBILE} {
      font-size: ${fontSize['3xl']};
   }
`;

const heroTextAlign = `
   text-align: center;
   ${MOBILE} {
      text-align: left;
   }
`;

const HeroTextContainer = styled.h1`
   ${heroTextFontSize};
   ${heroTextAlign};
   font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
   font-style: normal;
   font-weight: normal;
   padding-bottom: ${space[5]};
   margin: 0;
   color: ${color.white};
`;

const heroText = _js('Explore a World of Repair');

const SearchWrapper = styled.form`
   ${heroContentWidth};
   position: relative;
   z-index: 1;
`;

const ButtonWrapper = styled.div`
   display: grid;
   justify-content: center;
`;

const StyledSearchInput = styled(SearchInput)`
   height: ${space[8]};
   border-radius: ${borderRadius.md};
   input {
      background-color: white;
   }
   input:focus {
      box-shadow: none;
      border-color: inherit;
   }
`;

// c.f. https://stackoverflow.com/questions/9038625/detect-if-device-is-ios/9039885#9039885
let autoFocus = !(navigator.platform && /iphone|ipod|ipad/i.test(navigator.platform));

const searchInputProps = {
   autoFocus,
};

const handleSubmit = value => event => {
   trackInPiwikAndGA({
      eventCategory: 'Search',
      eventAction: 'Search - Homepage Hero',
   });
   event.preventDefault();
   document.location = '/Search/' + encodeURIComponent(value);
};

export default function HomePageSearchHero(props) {
   const [value, setValue] = useState('');
   /* Translators: Prompting the user to search for the phrase %1 */
   const prompt = _js('Try "%1"', props.prompt);
   return (
      <SearchWrapper action="Search" onSubmit={handleSubmit(value)}>
         <HeroTextContainer>{heroText}</HeroTextContainer>
         <StyledSearchInput
            value={value}
            onChange={event => setValue(event.target.value)}
            placeholder={prompt}
            {...searchInputProps}
         />
         <ButtonWrapper>
            <Text fontWeight="500" color="white" align="center">
               {_js('or')}
            </Text>
            <Button
               display="flex"
               gap="6px"
               color="white"
               fontWeight="600"
               borderRadius="md"
               minHeight="32px"
               py="9px"
               px="16px"
               border="1px solid rgba(255, 255, 255, 0.40)"
               background="rgba(255, 255, 255, 0.15)"
               _hover={{ background: 'rgba(255, 255, 255, 0.15)' }}
               onClick={deviceNavigator}
            >
               <FontAwesomeIcon icon={faList} />
               {_js('Find Your Device')}
            </Button>
         </ButtonWrapper>
      </SearchWrapper>
   );
}
