import { memo, forwardRef } from 'react';
import { space } from '@ifixit/primitives';
import { Input, InputLeftElement, InputGroup, InputProps, Box, BoxProps } from '@chakra-ui/react';
import { FaIcon } from '@ifixit/icons';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlass';

type SearchInputProps = {
   className?: string;
   containerProps?: BoxProps;
} & InputProps;

const SearchInputComponent = ({ containerProps, ...props }: SearchInputProps, ref: any) => (
   <Box className={props?.className} {...containerProps}>
      <InputGroup>
         <InputLeftElement fontSize="20px" m="7px 5px" color="var(--chakra-colors-gray-400)">
            <FaIcon icon={faMagnifyingGlass} />
         </InputLeftElement>
         <Input
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            height={space[8]}
            fontSize="16px"
            type="search"
            aria-label={_js('Search')}
            placeholder={_js('Search')}
            ref={ref}
            paddingLeft="44px"
            {...props}
         />
      </InputGroup>
   </Box>
);

export const SearchInput = memo(
   forwardRef<HTMLInputElement, SearchInputProps>(SearchInputComponent)
);
