import { _js } from '@ifixit/localize';
import { Button, Hide, Text } from '@chakra-ui/react';
import {
   space,
   borderRadius,
   shadow,
   color,
   fontFamily,
   fontSize,
   lineHeight,
   breakpoint,
} from '@ifixit/primitives';
import styled from 'styled-components';
import { Document } from './featured_documents_types';

const MOBILE = `@media only screen and (max-width: ${breakpoint.sm})`;
const TABLET = `@media only screen and (max-width: ${breakpoint.md})`;

const Highlight = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
`;

const Details = styled.div`
   flex: 1;
   margin-left: ${space[7]};

   ${TABLET} {
      margin-left: 0;
   }
`;

const DocumentTitle = styled.a`
   display: block;
   &:hover {
      text-decoration: none;
   }
   font-size: 1.5rem;
   font-weight: bold;
   font-family: ${fontFamily.sansSystem};
   line-height: 1.8125rem;
   color: ${color.gray[800]};
`;

const ImageContainer = styled.div`
   max-width: 400px;
   flex: 1;

   ${TABLET} {
      height: auto;
      width: 100%;
      margin-bottom: ${space[5]};
   }
`;

const Image = styled.img`
   border-radius: ${borderRadius.md};
   box-shadow: ${shadow[2]};
   width: 100%;
   height: auto;
`;

// https://css-tricks.com/almanac/properties/l/line-clamp/
const Summary = styled.div`
   font-size: ${fontSize.xl};
   ${MOBILE} {
      font-size: ${fontSize.lg};
   }
   line-height: ${lineHeight.normal};
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 6;
   line-clamp: 6;
   overflow: hidden;
   text-overflow: ellipsis;
   color: ${color.gray[600]};
   margin: ${space[4]} 0;
`;

type DocumentHighlightProps = { className?: string; document: Document };

const buttonTextMap = {
   guide: _js('View the Guide'),
   wiki: _js('View the Wiki'),
};

function DocumentHighlight(props: DocumentHighlightProps) {
   const { className, document }: DocumentHighlightProps = props;
   const buttonText = document.isTroubleshooting
      ? _js('Troubleshoot My Device')
      : buttonTextMap[document.type];
   return (
      <Highlight className={className}>
         <ImageContainer>
            <a href={document.url} aria-label={document.title}>
               <Image
                  src={document.image}
                  role="presentation"
                  loading="lazy"
                  width="372"
                  height="279"
               />
            </a>
         </ImageContainer>
         <Details>
            <DocumentTitle href={document.url}>{document.title}</DocumentTitle>
            <Summary>{document.summary}</Summary>
            <Hide breakpoint={`(max-width: ${breakpoint.sm})`}>
               <Button as="a" href={document.url} variant="cta">
                  <Text color="white" fontWeight="700">
                     {buttonText}
                  </Text>
               </Button>
            </Hide>
         </Details>
      </Highlight>
   );
}

export default DocumentHighlight;
